import React from 'react';
import { Helmet } from 'react-helmet';

// ... (rest of the About component remains the same)

const About = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      <Helmet>
        <title>About PhenoAge Calculator | Biological Age Estimation</title>
        <meta name="description" content="Learn about the PhenoAge Calculator, a tool for estimating biological age based on biomarkers. Understand its methodology, uses, and limitations." />
      </Helmet>

      <h1 className="text-3xl font-bold mb-6 text-gray-800 dark:text-white">About PhenoAge Calculator</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-gray-700 dark:text-gray-200">What is PhenoAge?</h2>
        <p className="text-gray-600 dark:text-gray-300 mb-4">
          PhenoAge is a measure of biological age developed by researchers at Yale University. It uses a combination of clinical biomarkers and chronological age to estimate an individual's biological age, which can differ from their chronological age.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-gray-700 dark:text-gray-200">How Does the Calculator Work?</h2>
        <p className="text-gray-600 dark:text-gray-300 mb-4">
          Our PhenoAge Calculator uses the algorithm developed by Levine et al. (2018) to estimate biological age based on the following biomarkers:
        </p>
        <ul className="list-disc list-inside text-gray-600 dark:text-gray-300 mb-4">
          <li>Albumin</li>
          <li>Creatinine</li>
          <li>Glucose</li>
          <li>C-reactive protein</li>
          <li>Lymphocyte percentage</li>
          <li>Mean cell volume</li>
          <li>Red cell distribution width</li>
          <li>Alkaline phosphatase</li>
          <li>White blood cell count</li>
          <li>Chronological age</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-gray-700 dark:text-gray-200">Limitations and Considerations</h2>
        <ul className="list-disc list-inside text-gray-600 dark:text-gray-300 mb-4">
          <li>PhenoAge is an estimation and should not be considered a definitive measure of health or longevity.</li>
          <li>The calculator is based on population-level data and may not account for individual variations or specific health conditions.</li>
          <li>Results can be influenced by temporary factors such as recent illness, medication, or lifestyle changes.</li>
          <li>The original study was conducted on a US population and may not be equally applicable to all ethnic groups or populations.</li>
          <li>PhenoAge should not be used as a substitute for professional medical advice, diagnosis, or treatment.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-gray-700 dark:text-gray-200">How to Use the Results</h2>
        <p className="text-gray-600 dark:text-gray-300 mb-4">
          The PhenoAge Calculator results can provide insights into your overall health status and potential longevity. However, it's important to:
        </p>
        <ul className="list-disc list-inside text-gray-600 dark:text-gray-300 mb-4">
          <li>Discuss the results with a healthcare professional for proper interpretation.</li>
          <li>Use the results as motivation for maintaining or improving healthy lifestyle habits.</li>
          <li>Remember that biological age can change over time with changes in health and lifestyle.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4 text-gray-700 dark:text-gray-200">References</h2>
        <p className="text-gray-600 dark:text-gray-300 mb-4">
          Levine, M. E., Lu, A. T., Quach, A., Chen, B. H., Assimes, T. L., Bandinelli, S., ... & Horvath, S. (2018). An epigenetic biomarker of aging for lifespan and healthspan. Aging (Albany NY), 10(4), 573.
        </p>
      </section>

      <section>
        <h3 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">Frequently Asked Questions</h3>
        
        <div className="space-y-4">
          <div>
            <h4 className="text-xl font-semibold mb-2 text-gray-800 dark:text-white">What is PhenoAge?</h4>
            <p className="text-gray-700 dark:text-gray-300">
              PhenoAge is a measure of biological age based on nine biomarkers and chronological age. 
              It aims to provide a more accurate assessment of your overall health and potential lifespan 
              than chronological age alone.
            </p>
          </div>

          <div>
            <h4 className="text-xl font-semibold mb-2 text-gray-800 dark:text-white">How accurate is the PhenoAge Calculator?</h4>
            <p className="text-gray-700 dark:text-gray-300">
              While PhenoAge has been shown to be a robust predictor of health outcomes in research studies, 
              it's important to remember that it's an estimate. Individual results may vary, and this calculator 
              should not be used as a substitute for professional medical advice.
            </p>
          </div>

          <div>
            <h4 className="text-xl font-semibold mb-2 text-gray-800 dark:text-white">What should I do with my PhenoAge result?</h4>
            <p className="text-gray-700 dark:text-gray-300">
              Your PhenoAge result can be a useful tool for understanding your overall health. If your PhenoAge 
              is higher than your chronological age, it might be worth discussing lifestyle changes or further 
              health assessments with your healthcare provider. Remember, many factors contributing to PhenoAge 
              can be improved through lifestyle changes.
            </p>
          </div>

          <div>
            <h4 className="text-xl font-semibold mb-2 text-gray-800 dark:text-white">How often should I calculate my PhenoAge?</h4>
            <p className="text-gray-700 dark:text-gray-300">
              PhenoAge can change over time, reflecting changes in your health. It's generally recommended to 
              recalculate your PhenoAge annually or when you have new blood test results. However, dramatic 
              changes over short periods should be interpreted cautiously and discussed with a healthcare professional.
            </p>
          </div>
        </div>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4 text-gray-700 dark:text-gray-200">References</h2>
        <p className="text-gray-600 dark:text-gray-300 mb-4">
          Levine, M. E., Lu, A. T., Quach, A., Chen, B. H., Assimes, T. L., Bandinelli, S., ... & Horvath, S. (2018). An epigenetic biomarker of aging for lifespan and healthspan. Aging (Albany NY), 10(4), 573.
        </p>
      </section>
    </div>
  );
};

export default About;
