import React, { useState } from 'react';
import GaugeChart from 'react-gauge-chart';
import { QRCodeSVG } from 'qrcode.react';

const Calculator = ({ darkMode }) => {
  const [inputs, setInputs] = useState({
    birthday: '1988-04-15',
    testDate: '2024-07-08',
    albumin: '4.9',
    creatinine: '1.57',
    glucose: '88.2',
    crp: '0.6',
    lymphocyte: '46.4',
    mcv: '92.8',
    rdw: '12.3',
    alkalinePhosphatase: '95',
    whiteBloodCell: '5.2',
  });

  const [results, setResults] = useState(null);

  const inputFields = [
    { name: 'birthday', label: 'Birthday', type: 'date', unit: '' },
    { name: 'testDate', label: 'Date of test', type: 'date', unit: '' },
    { name: 'albumin', label: 'Albumin', type: 'number', unit: 'g/dL', step: '0.1' },
    { name: 'creatinine', label: 'Creatinine', type: 'number', unit: 'mg/dL', step: '0.01' },
    { name: 'glucose', label: 'Glucose', type: 'number', unit: 'mg/dL', step: '0.1' },
    { name: 'crp', label: 'C-reactive Protein', type: 'number', unit: 'mg/L', step: '0.1' },
    { name: 'lymphocyte', label: 'Lymphocyte', type: 'number', unit: '%', step: '0.1' },
    { name: 'mcv', label: 'Mean Cell Volume', type: 'number', unit: 'fL', step: '0.1' },
    { name: 'rdw', label: 'Red Cell Dist Width', type: 'number', unit: '%', step: '0.1' },
    { name: 'alkalinePhosphatase', label: 'Alkaline Phosphatase', type: 'number', unit: 'U/L', step: '1' },
    { name: 'whiteBloodCell', label: 'White Blood Cells', type: 'number', unit: '10^3 cells/mL', step: '0.1' },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs(prevInputs => ({
      ...prevInputs,
      [name]: value
    }));
  };

  const calculatePhenoAge = () => {
    const intercept = -19.907;
    const gamma = 0.0076927;
    const tmonths = 120; // 10 years for mortality calculation

    const weights = {
      age: 0.0804,
      albumin: -0.0336,
      creatinine: 0.0095,
      glucose: 0.1953,
      crp: 0.0954,
      lymphocyte: -0.012,
      mcv: 0.0268,
      rdw: 0.3306,
      alkalinePhosphatase: 0.0019,
      whiteBloodCell: 0.0554,
    };

    const conversions = {
      albumin: (value) => value * 10, // g/dL to g/L
      creatinine: (value) => value * 88.401, // mg/dL to μmol/L
      glucose: (value) => value * 0.0555, // mg/dL to mmol/L
      crp: (value) => value * 0.1, // mg/L to mg/dL
      lymphocyte: (value) => value * 1, // % (no conversion needed)
      mcv: (value) => value, // fL (no conversion needed)
      rdw: (value) => value, // % (no conversion needed)
      alkalinePhosphatase: (value) => value, // U/L (no conversion needed)
      whiteBloodCell: (value) => value, // 10^3 cells/mL (no conversion needed)
    };

    // Calculate chronological age
    const birthDate = new Date(inputs.birthday);
    const testDate = new Date(inputs.testDate);
    const ageInMilliseconds = testDate - birthDate;
    const chronologicalAge = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000);

    let rollingTotal = intercept;

    // Process each biomarker
    for (const [key, value] of Object.entries(weights)) {
      let biomarkerValue = parseFloat(inputs[key]);

      if (key === 'age') {
        biomarkerValue = chronologicalAge;
      } else if (conversions[key]) {
        biomarkerValue = conversions[key](biomarkerValue);
      }

      // Apply log transformation to CRP after conversion
      if (key === 'crp') {
        biomarkerValue = Math.log(biomarkerValue);
      }

      rollingTotal += biomarkerValue * value;
    }

    // Calculate mortality score
    const mortalityScore = 1 - Math.exp(-Math.exp(rollingTotal) * (Math.exp(gamma * tmonths) - 1) / gamma);

    // Calculate PhenoAge
    const calculatedPhenoAge = 141.50225 + Math.log(-0.00553 * Math.log(1 - mortalityScore)) / 0.090165;

    // Calculate risk of death in the next year
    const riskOfDeath = 1 - Math.exp(-Math.exp(rollingTotal) * (Math.exp(gamma * 12) - 1) / gamma);

    // Calculate the gauge percent
    const ageDifference = calculatedPhenoAge - chronologicalAge;
    let gaugePercent;
    if (ageDifference <= -10) {
      gaugePercent = 0;
    } else if (ageDifference >= 10) {
      gaugePercent = 1;
    } else {
      gaugePercent = (ageDifference + 10) / 20;
    }

    // Set the results
    setResults({
      chronologicalAge: chronologicalAge.toFixed(2),
      phenoAge: calculatedPhenoAge.toFixed(2),
      ageDifference: ageDifference.toFixed(2),
      riskOfDeath: (riskOfDeath * 100).toFixed(2),
      gaugePercent: gaugePercent
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    calculatePhenoAge();
  };

  return (
    <div className="max-w-xl mx-auto p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white">PhenoAge Calculator</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {inputFields.map((field) => (
          <div key={field.name}>
            <label htmlFor={field.name} className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              {field.label} {field.unit && `(${field.unit})`}
            </label>
            <input
              type={field.type}
              id={field.name}
              name={field.name}
              value={inputs[field.name]}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              required
              step={field.step}
            />
          </div>
        ))}
        <button
          type="submit"
          className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
        >
          Calculate PhenoAge
        </button>
      </form>
      {results && (
        <div className="mt-8 p-6 bg-blue-50 dark:bg-blue-900 rounded-lg shadow">
          <h3 className="text-2xl font-bold mb-4 text-center text-blue-800 dark:text-blue-200">
            Your Biological Age
          </h3>
          <div className="flex flex-col items-center mb-4">
            <div className="w-full max-w-xs">
              <GaugeChart
                id="age-gauge-chart"
                nrOfLevels={5}
                colors={["#4CAF50", "#8BC34A", "#FFC107", "#FF9800", "#F44336"]}
                percent={results.gaugePercent}
                formatTextValue={(value) => `${results.phenoAge} years`}
                textColor={darkMode ? "#FFFFFF" : "#000000"}
                needleColor={darkMode ? "#FFFFFF" : "#000000"}
                needleBaseColor={darkMode ? "#FFFFFF" : "#000000"}
              />
            </div>
          </div>
          <div className="text-center space-y-2">
            <p className="text-lg text-blue-700 dark:text-blue-300">
              <span className="font-medium">Chronological Age:</span> {results.chronologicalAge} years
            </p>
            <p className={`text-lg font-medium ${parseFloat(results.ageDifference) > 0 ? 'text-red-600 dark:text-red-400' : 'text-green-600 dark:text-green-400'}`}>
              {parseFloat(results.ageDifference) > 0 ? 'Older' : 'Younger'} by {Math.abs(results.ageDifference)} years
            </p>
            <div className="mt-4 p-3 bg-white dark:bg-gray-800 rounded-lg flex items-center justify-center space-x-4">
              <p className="text-md text-blue-700 dark:text-blue-300">
                Get yours at age.gio.fyi
              </p>
              <QRCodeSVG 
                value="https://age.gio.fyi" 
                size={64}
                fgColor={darkMode ? "#90CDF4" : "#2B6CB0"}
                bgColor="transparent"
                level="L"
                includeMargin={false}
              />
            </div>
          </div>
        </div>
      )}
      <div className="mt-8 text-sm text-gray-600 dark:text-gray-400">
        <p>Disclaimer: This calculator provides an estimate based on statistical models and should not be considered as medical advice. Always consult with healthcare professionals for proper interpretation of your health status.</p>
      </div>
    </div>
  );
};

export default Calculator;
