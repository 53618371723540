import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white dark:bg-gray-800 shadow-md mt-8 py-4">
      <p className="text-center text-gray-600 dark:text-gray-400">&copy; 2024 gioFYI. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
